import * as fns from 'date-fns';

export const getTicketDuration = (milliseconds: number) => {
    const duration = fns.intervalToDuration({
        start: 0,
        end: milliseconds,
    });

    if (duration.days === 1) {
        duration.hours = 24;
        duration.days = 0;
    }

    if (duration.months !== 0 && typeof duration.months === `number` && typeof duration.days === `number`) {
        duration.days += duration.months * 30;
        duration.months = 0;
    }

    const returnDuration = fns.formatDuration(duration, {
        format: [`years`, `months`, `days`, `hours`, `minutes`, `seconds`],
    });

    let formattedReturnDuration = returnDuration
        .replace(`years`, `lat`)
        .replace(`year`, `rok`)
        .replace(`days`, `dni`)
        .replace(`day`, `dni`)
        .replace(`hours`, `h`)
        .replace(`hour`, `h`)
        .replace(`minutes`, `min`)
        .replace(`minute`, `min`)
        .replace(`seconds`, `sek`)
        .replace(`second`, `sek`);

    const timeFormatChecker = formattedReturnDuration.split(` `);

    if (timeFormatChecker.length > 4) {
        formattedReturnDuration = timeFormatChecker.slice(0, 4).join(` `);
    }

    return formattedReturnDuration;
};
